import React, {useState}from 'react';
import Mailto from 'react-protected-mailto'

import { validateContactForm, notifyInfo, notifyError } from '../utils';

function Contact() {

  const [notification, setNotification] = useState('');

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const handleSubmit = e => {
    e.preventDefault();
    const target = e.target
    const data = new FormData(target);
    const inputSubmit = e.target[3];
    inputSubmit.disabled = true;
    
    var obj = {};
    data.forEach((value, key) => obj[key] = value);
    
    // validate input
    if(validateContactForm({name: obj['name'], email: obj['email'], message: obj['message']})) return 
    
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...obj })
    })
    .then((data) => {
      if(data.status !== 200) return notifyError('Your information was not sent. Please try again later.')
      notifyInfo('Thank you for contacting us!')
      return target.reset()
    })
    .catch(error => console.error(error));
    
    inputSubmit.disabled = false;
  }

  return (
    <section id="contact" className="contact">
      <svg
        className="contact-svg"
        preserveAspectRatio="none"
        viewBox="0 0 100 102"
        height="75"
        width="100%"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0 L50 100 L100 0 Z" fill="#FAFAFA" stroke="none"></path>
      </svg>

      <h2 className="contact-title" data-aos="fade-right">
        CONTACT
      </h2>
      <div
        className="title-bar contact-bar"
        data-aos="fade-right"
        data-aos-anchor=".contact-title"
        data-aos-delay="200"
      ></div>

      <div className="contact-text">
        <p data-aos="fade" data-aos-duration="1000">
          Have a question or want to work together?
        </p>
        <p
          data-aos="fade-up"
          data-aos-anchor=".contact-text"
          data-aos-duration="500"
          data-aos-delay="1000"
        >
          please, use the form below or send an email to{" "}
          
          <Mailto className="contact-mail" email='hey@fullwebstack.com' />

        </p>
      </div>

      <form className="contact-form" netlify  onSubmit={handleSubmit}>
        <div className="name-container">
          <label
            className="label-name"
            htmlFor="name"
            data-aos="fade-right"
            data-aos-anchor=".contact-text"
            data-aos-delay="1500"
            data-aos-duration="1000"
          >
            Name
          </label>
          <input
            className="input-field"
            id="name"
            name="name"
            type="text"
            placeholder="Enter name here"
            data-aos="fade-up"
            data-aos-anchor=".contact-text"
            data-aos-delay="1000"
            data-aos-duration="1000"
            aria-required={true}
            required
          />
        </div>

        <div className="email-container">
          <label
            className="label-email"
            htmlFor="email"
            data-aos="fade-right"
            data-aos-anchor=".contact-text"
            data-aos-delay="1500"
            data-aos-duration="1000"
          >
            Email
          </label>
          <input
            className="input-field"
            id="email"
            name="email"
            type="email"
            placeholder="Enter email here"
            data-aos="fade-up"
            data-aos-anchor=".contact-text"
            data-aos-delay="1000"
            data-aos-duration="1000"
            aria-required={true}
            required
          />
        </div>

        <div className="message-container">
          <label
            className="label-message"
            htmlFor="message"
            data-aos="fade-right"
            data-aos-anchor=".contact-text"
            data-aos-delay="1500"
            data-aos-duration="1000"
          >
            Your message
          </label>
          <textarea
            id="message"
            className="input-textarea"
            name="message"
            placeholder="Enter message here"
            minLength="10"
            data-aos="fade-up"
            data-aos-anchor=".contact-text"
            data-aos-delay="1000"
            data-aos-duration="1000"
            aria-required={true}
            required
          />
        </div>

        {/* {notification === 'success' && 
          <div className="notification notification-success">Thank you for contacting us!</div>
        }

        {notification === 'error' &&
          <div className="notification notification-error">Your information was not sent. Please try again later.</div>
        } */}

        <input
          className="input-submit"
          type="submit" 
          defaultValue="Send"
          data-aos="fade-left"
          data-aos-anchor=".contact-text"
          data-aos-delay="2000"
          data-aos-duration="1000"
        />

      </form>
    </section>
  );
}

export default Contact;