import { toast } from 'react-toastify';


export const notifyInfo  = text => toast.info(text);

export const notifyError = text => toast.error(text);

export const trimSpaceLowerCase = txt => txt.replace(/ /g, '').toLowerCase();

export const validateContactForm = ({name, email, message}) => {
  
  const messageLength = message.length || 0;
  
  if(!/^[a-zA-Z ]{3,30}$/.test(name)) return true
  if(!/^[\w.!#$%&'*+/=?^`{|}~-]+@[\w-]+\.[\w-]+$/.test(email)) return true
  if(messageLength < 10) return true
  
  return false
}